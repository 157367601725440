import { TYPE_CONTENT, MONTHS } from '../constants/appConstants'

const formatDateForMyOrders = _date => {
	const date = new Date(_date)
	return `${date.getDate()} de ${
		MONTHS[date.getMonth()]
	} de ${date.getFullYear()}`
}

const OrdersUtils = {
	formatMyOrders: myOrders => {
		return (
			myOrders &&
			myOrders.map(order => ({
				content: [
					{
						title: `PEDIDO #${order.sampleId}`,
						subtitle: !order.dateDelivery
							? `Fecha de solicitud: ${formatDateForMyOrders(
									order.dateRequest
							  )}`
							: `Fecha de entrega: ${formatDateForMyOrders(
									order.dateDelivery
							  )}`,
						type: TYPE_CONTENT.PRINCIPAL,
						countProduct: order.countProduct,
					},
				],
				id: order.sampleId,
				image: order.image,
				dateDelivery: order.dateDelivery,
				state: order.state,
				description: order.description,
			}))
		)
	},
	formatDateForMyOrders,
}

export default OrdersUtils
