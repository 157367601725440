import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import LayoutModal from '../../../shared/components/organisms/layoutModal/layout-modal'
import TargetOrder from '../../atoms/target-order/target-order'
import Spacer from '../../../shared/components/atoms/spacer/spacer'
import './modal-order.scss'
import { TYPE_CONTENT } from '../../../shared/state/constants/appConstants'
import logo from '../../../shared/assets/images/logo-tena.svg'

const ModalOrder = props => {
	const { hideAction, order, items } = props
	const query = useStaticQuery(graphql`
		query MODAL_ORDER_QUERY {
			allContentfulTenaProductos(filter: { node_locale: { eq: "es-CO" } }) {
				nodes {
					codigoDeProducto
					nombreProducto
					imagenProductoCompleta {
						file {
							url
						}
					}
				}
			}
		}
	`)
	const products = query.allContentfulTenaProductos.nodes.map(product => ({
		id: product.codigoDeProducto,
		image: product.imagenProductoCompleta?.file?.url || '',
		title: product.nombreProducto,
		type: TYPE_CONTENT.OTHER,
	}))

	return (
		<LayoutModal>
			<div className='f-card-order-modal'>
				<div className='f-card-order-modal-head'>
					<span className='f-card-order-modal-head-icon' onClick={hideAction}>
						X
					</span>
				</div>
				<div className='f-card-order-modal-content'>
					<div className='f-card-order-modal-content-l1'>
						<div className='f-card-order-modal-content-l1-container'>
							<span className='f-card-order-modal-content-l1-tag'>Muestra</span>
							<Spacer px={13} />
							<h1 className='f-card-order-modal-content-l1-title'>
								{order.content[0].title}
							</h1>
							<p className='f-card-order-modal-content-l1-date'>
								{order.content[0].subtitle}
							</p>
						</div>
						<div className='f-card-order-modal-content-l1-container'>
							<span className='f-card-order-modal-content-l1-tdir'>
								Dirección de entrega
							</span>
							<p className='f-card-order-modal-content-l1-dir'>
								{items.deliveryAddress + ' ' + items.deliveryCity}
							</p>
						</div>
					</div>
					<div className='f-card-order-modal-content-separator'></div>
					<div className='f-card-order-modal-content-l2'>
						{items &&
							items.products.map((item, key) => {
								let product = products.find(p => p.id === item.id)
								console.log(
									'🚀 ~ file: modal-order.jsx ~ line 56 ~ {items&&items.products.map ~ product',
									product
								)

								if (!product) {
									product = {
										image: logo,
										title: 'Producto TENA',
										type: 'other',
									}
								}
								product.subtitle = 'Cantidad: 1'
								return (
									<TargetOrder
										key={key}
										img={product.image}
										content={[product]}
										mode={'detail'}
										isSample={false}
									/>
								)
							})}
					</div>
				</div>
			</div>
		</LayoutModal>
	)
}

export default ModalOrder
