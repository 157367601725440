import React from 'react'
import TargetOrder from '../../atoms/target-order/target-order'
import useProducts from '../../../shared/hooks/useProducts'
import { TYPE_CONTENT } from '../../../shared/state/constants/appConstants'
import '../../../components/molecules/modal-order/modal-order.scss'
import logo from '../../../shared/assets/images/logo-tena.svg'
import './dropDownCard.scss'
import Loader from '../../../shared/components/atoms/Loader/Loader'

const DropDownCard = ({ items, setIsActiveDropDownCard, isShowingLoader }) => {
	const { currentCountryProducts } = useProducts()
	const productsCMS = currentCountryProducts.map(product => ({
		id: product.codigoDeProducto,
		image: product.imagenProductoCompleta.file.url,
		title: product.nombreProducto,
		type: TYPE_CONTENT.OTHER,
	}))

	return (
		<div className='f-dropDown-wrapper'>
			<div className='f-dropDown-content-l1-container--top'>
				<span className='f-dropDown-content-l1-dir'>Dirección de entrega</span>
				<p className='f-dropDown-content-l1-dir'>
					{items.deliveryAddress.replaceAll('?',' ') + ' ' + items.deliveryCity}
				</p>
				<div className='f-dropDown-content-separator'></div>
			</div>

			<div className='f-dropDown-content-l2' id='containerL2'>
				{isShowingLoader ? (
					<Loader class={'primary'} />
				) : (
					items?.products?.map((item, key) => {
						let productCMS = productsCMS.find(p => p.id === item.id)
						if (!productCMS) {
							productCMS = {
								image: logo,
								title: 'Producto TENA',
								type: 'other',
							}
						}
						productCMS.subtitle = 'Cantidad: 1'
						productCMS.size = item.size

						return (
							<TargetOrder
								key={key}
								img={productCMS.image}
								content={[productCMS]}
								mode='sendCard'
								isSample={false}
							/>
						)
					})
				)}
			</div>
			<div className='f-dropDown-content-separator--down'></div>
			<div className='f-dropDown-content'>
				<div className='f-dropDown-content-l1-container--down'>
					<span className='f-dropDown-content-l1-dir'>
						Dirección de entrega
					</span>
					<p className='f-dropDown-content-l1-dir'>
						{items.deliveryAddress.replaceAll('?',' ') + ' ' + items.deliveryCity}
					</p>
				</div>
				<button
					className='f-faqcard-button   f-faqcard-button--cardDrop'
					onClick={setIsActiveDropDownCard}
				>
					Ocultar detalles
				</button>
			</div>
		</div>
	)
}
export default DropDownCard
