import { graphql, useStaticQuery } from 'gatsby'
export default function useProducts() {
	const result = useStaticQuery(graphql`
		query PRODUCTS_ORDER {
			allContentfulTenaProductos {
				nodes {
					node_locale
					perteneceAEstePais
					codigoDeProducto
					nombreProducto
					imagenProductoCompleta {
						file {
							url
						}
					}
				}
			}
		}
	`)
	const allCountriesProducts = result.allContentfulTenaProductos.nodes
	const currentCountryProducts = allCountriesProducts.filter(
		e => e.node_locale === process.env.LOCALE && e.perteneceAEstePais
	)

	return { allCountriesProducts, currentCountryProducts }
}
