import React from 'react'
import Icon from '../../../shared/components/atoms/Icon/Icon'
import './state-send.scss'
const StateSend = ({ states }) => {
	const isActiveState = state => state.active
	const indexActiveState = states.findIndex(isActiveState)

	return (
		<div className='f-state-send'>
			{states.map((state, key) => {
				return (
					<div
						key={key}
						className={`f-state-send-item f-item-${state.tag || 'center'}`}
					>
						<div className='f-state-send-item-phase'>
							<div
								className='f-state-send-item-circle'
								style={{
									backgroundColor:
										key <= indexActiveState ? state.color : '#839DAE',
								}}
							>
								<span className='f-state-send-item-title'>{state.name}</span>
								<Icon icon={state.icon} size='1' tagtype='center' />
							</div>
							{state.tag !== 'last' && (
								<div
									className='f-state-send-item-bar'
									style={{
										backgroundColor:
											key < indexActiveState ? state.color : '#fff',
									}}
								/>
							)}
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default StateSend
