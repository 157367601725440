import React from 'react'
import Icon from '../../../shared/components/atoms/Icon/Icon'
import './state-error.scss'

const StateError = props => {
	return (
		<div className='f-state-error'>
			<p className='f-state-error-state'>
				<Icon size='x1' tagtype='span' icon='icon-exclamation-circle tango' />
				{props.state}
			</p>
			<p className='f-state-error-copy'>{props.description}</p>
		</div>
	)
}

export default StateError
