import React from 'react'
import PropTypes from 'prop-types'
import './target-order.scss'
import { TYPE_CONTENT } from '../../../shared/state/constants/appConstants'

const TargetOrder = ({ mode, isSample, content, img }) => {
	const isHistory = mode === 'detail'
	return (
		<div
			className={`f-target-order  ${
				mode === 'sendCard' ? 'f-target-order--card' : ''
			} ${isHistory ? 'f-target-order--history' : ''}  `}
		>
			{(mode === 'send' || mode === 'sendCard') && (
				<div className='f-target-order-cover'>
					<div
						className='f-target-order-cover-img'
						style={{ backgroundImage: `url(${img})` }}
					></div>
					{isSample && (
						<span className='f-target-order-cover-tag'>Muestra</span>
					)}
				</div>
			)}
			<div
				className={`f-target-order-content  ${
					mode === 'detail' ? 'f-detail-order' : ''
				} `}
			>
				{content &&
					content.map((item, key) => {
						switch (item.type) {
							case TYPE_CONTENT.PRINCIPAL:
								return (
									<div
										className={`f-target-order-content-principal ${
											mode === 'detail'
												? 'f-target-order-content-principal--history'
												: ''
										}`}
									>
										<span className='f-target-order-content-principal-title'>
											{item.title}
										</span>
										<span className='f-target-order-content-principal-subtitle'>
											{item.subtitle}
										</span>
										<span
											className={`  f-target-order-content-other-subtitle  ${
												mode === 'send'
													? 'f-target-order-content-other-subtitle--main'
													: ''
											}`}
										>
											{`${
												item.countProduct < 2
													? `${item.countProduct} Producto`
													: `${item.countProduct} Productos`
											}`}
										</span>
									</div>
								)
							case TYPE_CONTENT.OTHER:
								return (
									<div className='f-target-order-content-other' key={key}>
										<span className='f-target-order-content-other-title'>
											{item.title}
										</span>
										{item?.size && (
											<span className='f-target-order-content-other-title'>
												{`Talla: ${item.size}`}
											</span>
										)}

										<span className='f-target-order-content-other-subtitle'>
											{item.subtitle}
										</span>
									</div>
								)
							default:
								return <></>
						}
					})}
			</div>
		</div>
	)
}

TargetOrder.propTypes = {
	mode: PropTypes.oneOf(['send', 'detail']).isRequired,
}

export default TargetOrder
