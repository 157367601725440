import { InputLabel, MenuItem, Select } from '@material-ui/core'
import { graphql } from 'gatsby'
import React, { Fragment } from 'react'
import BannerTitle from '../../components/atoms/banner-title/banner-title'
import AccountScoreHeader from '../../components/molecules/accountScoreHeader/accountScoreHeader'
import ModalOrder from '../../components/molecules/modal-order/modal-order'
import Order from '../../components/molecules/order/order'
import Spacer from '../../shared/components/atoms/spacer/spacer'
import TabProducts from '../../shared/components/molecules/tabProducts/tabProducts'
import Layout from '../../shared/components/organisms/layout/layout'
import SEO from '../../shared/components/seo'
import useBrowserMode from '../../shared/hooks/useBrowserMode'
import useHomeLinkMode from '../../shared/hooks/useHomeLinkMode'
import useLocaleMode from '../../shared/hooks/useLocaleMode'
import useMisPedidos from '../../shared/hooks/useMisPedidos'
import useSessionState from '../../shared/hooks/useSessionState'
import SessionService from '../../shared/services/session'
import OrdersUtils from '../../shared/state/utils/orders-utils'
import SearchAdviser from '../../shared/components/atoms/search-adviser/search-adviser'

const MisPedidos = props => {
	const { isBrowser } = useBrowserMode()
	const { homeLink } = useHomeLinkMode()
	const { actualStorage, isEffect } = useSessionState()
	const menu = props.data.allContentfulTenaMenu.nodes[0]
	const {
		isMobile,
		currentPage,
		isModalOrder,
		hideModal,
		responseOrders,
		getInfoOrder,
		infoOrder,
		orderSelect,
		filterAnios,
		anioSelect,
		isShowingLoader,
	} = useMisPedidos()
	const myOrders = responseOrders
	const styles = { paddingTop: '25px', width: '88%', margin: '0 auto' }
	const stylesH = { ...styles }
	stylesH.backgroundColor = 'white'
	const h1Style = {
		marginBottom: '20px',
		color: '#003C73',
	}
	const historyStyle = {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '0 80px',
		width: '88%',
		margin: '0 auto',
	}

	const historyStyleMobile = {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: '0 80px',
		// width: '88%',
		// margin: '0 auto',
	}
	const styleFilter = {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	}

	const styleFilterMobile = {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
	}

	const selectStyles = {
		color: '#7996A8',
		border: 'none',
	}

	const getFilterAnios = () => {
		const date = new Date()
		const anio = date.getFullYear()
		const anios = [anio, anio - 1, anio - 2]
		return anios.map((a, index) => (
			<MenuItem key={index} value={a}>
				{a}
			</MenuItem>
		))
	}
	const { locale } = useLocaleMode()
	const points = isBrowser && SessionService.getUserPoints()

	let storageInfo
	if (isEffect) {
		storageInfo =
			actualStorage === 0
				? localStorage.getItem('userData')
				: sessionStorage.getItem('userData')
		if (!storageInfo) {
			isBrowser && (window.location.href = homeLink)
		}
	}

	return (
		<>
			{isBrowser && (
				<Layout title='MIS PEDIDOS' isMobile={isMobile} currentPage={currentPage}>
					<article>
						<>
							<SearchAdviser />
						</>
						<SEO lang={locale} />
						<AccountScoreHeader actualScore={points} locale={locale} />
						<TabProducts tabs={menu} slug={'/mi-cuenta/mis-pedidos'} />
						<BannerTitle
							title={'MIS PEDIDOS'}
							description={
								'Aquí podrás rastrear tus pedidos, hacer devoluciones o comprar de nuevo.'
							}
							paddingLeft={'6%'}
						/>
						<div style={styles}>
							{myOrders &&
								OrdersUtils.formatMyOrders(myOrders).map((myOrder, index) => (
									<Fragment key={index}>
										<Order order={myOrder} mode='send' isSample={true} />
										<Spacer px={20} />
									</Fragment>
								))}
						</div>
						<div
							className='f-history'
							style={isMobile ? historyStyleMobile : historyStyle}
						>
							<h1 className='f-h1' style={h1Style}>
								HISTORIAL DE PEDIDOS ENTREGADOS
							</h1>
							<div
								className='f-history-filter'
								style={isMobile ? styleFilterMobile : styleFilter}
							>
								<InputLabel
									htmlFor='select-history'
									style={{ marginRight: 10 }}
								>
									Filtra tus pedidos
								</InputLabel>
								<Select
									name='select-history'
									style={selectStyles}
									id='select-history'
									value={anioSelect}
									onChange={filterAnios}
								>
									{getFilterAnios()}
								</Select>
							</div>
						</div>
						<div style={stylesH}>
							{myOrders &&
								OrdersUtils.formatMyOrders(myOrders)
									.filter(mo => mo.dateDelivery && mo.state === 'ENTREGADO')
									.map((myOrder, key) => (
										<Order
											key={key}
											order={myOrder}
											mode='detail'
											isShowingLoader={isShowingLoader}
											isSample={true}
											action={() => getInfoOrder(myOrder.id)}
										/>
									))}
							{/* {myOrders && OrdersUtils.formatMyOrders(myOrders).filter(mo => mo.state === "ENTREGADO").map((myOrder, key) => <Order key={key} order={myOrder} mode='detail' isShowingLoader={isShowingLoader} isSample={true} action={() => getInfoOrder(myOrder.id)} />)} */}
						</div>
						<Spacer px={60} hideon='x-large' />
						{isModalOrder && (
							<ModalOrder
								order={OrdersUtils.formatMyOrders(myOrders).find(
									mo => mo.id === orderSelect
								)}
								items={infoOrder}
								hideAction={hideModal}
							/>
						)}
					</article>
				</Layout>
			)}
		</>
	)
}

export const query = graphql`
	query MY_ACCOUNT_DATA_MP($language: String!) {
		allContentfulTenaMenu(
			filter: {
				node_locale: { eq: $language }
				titulo: { eq: "Menú > Mi Cuenta" }
			}
		) {
			nodes {
				opcionesMenu {
					nombre
					slug
					categoria {
						slug
					}
				}
			}
		}
	}
`

export default MisPedidos
