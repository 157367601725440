import { useEffect, useState } from 'react'
import SamplesService from '../services/samples'
import useMobileMode from './useMobileMode'
import useModalGeneric from './useModalGeneric'
import AdviserService from '../services/adviser'
import { isAdviser } from '../state/utils/isAdviser'
import SessionService from '../services/session'

export default function useMisPedidos() {
	const { isMobile, currentPage } = useMobileMode()
	const [isModalOrder, showModal, hideModal] = useModalGeneric(false)
	const [ordersAll, setOrdersAll] = useState()
	const [responseOrders, setResponseOrders] = useState()
	const [infoOrder, setInfoOrder] = useState()
	const [orderSelect, setOrderSelect] = useState()
	const [isShowingLoader, setShowingLoader] = useState(false)
	const [anioSelect, setAnioSelect] = useState(new Date().getFullYear())

	useEffect(() => {
		if (
			localStorage.getItem('tokenData') ||
			sessionStorage.getItem('tokenData')
		) {
			if (isAdviser(SessionService.getIdTokenData())) {
				AdviserService.getMyOrders(
					response => {
						const data = response.data
						const orders = data.status === 200 && data.data
						setResponseOrders(orders)
						setOrdersAll(orders)
					},
					error => {
						console.log(error)
					}
				)
			} else {
				SamplesService.getMyOrders(
					response => {
						const data = response.data
						const orders = data.status === 200 && data.data
						setResponseOrders(orders)
						setOrdersAll(orders)
					},
					error => {
						console.log(error)
					}
				)
			}
		}
	}, [])

	const getInfoOrder = async idOrder => {
		setShowingLoader(true)
		SamplesService.getForIdOrder(
			idOrder,
			response => {
				setOrderSelect(idOrder)
				setInfoOrder(response.data.data)
				// showModal();
				setShowingLoader(false)
			},
			error => {
				console.log(error)
				setShowingLoader(false)
			}
		)
	}

	const filterAnios = event => {
		const anio = event.target.value
		setAnioSelect(anio)
		setResponseOrders(
			ordersAll &&
				ordersAll.filter(order => {
					const dateRequest = new Date(order.dateRequest)
					return anio >= dateRequest.getFullYear() || !order.dateDelivery
				})
		)
	}

	return {
		isMobile,
		currentPage,
		isModalOrder,
		showModal,
		hideModal,
		responseOrders,
		getInfoOrder,
		infoOrder,
		orderSelect,
		filterAnios,
		anioSelect,
		isShowingLoader,
	}
}
