import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { STATUS_ORDERS } from '../../../shared/state/constants/appConstants'
import StateError from '../../atoms/state-error/state-error'
import StateSend from '../../atoms/state-send/state-send'
import TargetOrder from '../../atoms/target-order/target-order'
import DropDownCard from '../dropDownCard/dropDownCard'
import useMisPedidos from '../../../shared/hooks/useMisPedidos'
import './order.scss'

const Order = ({ mode, isSample, order, isShowingLoader, isActiveCard }) => {
	const { getInfoOrder, infoOrder } = useMisPedidos()
	const [isActiveDropDownCard, setIsActiveDropDownCard] = useState(false)

	return (
		<div className='f-contentf'>
			<div className={`f-order  ${mode === 'detail' ? 'f-history-order' : ''}`}>
				<div className='f-order-detail'>
					<TargetOrder
						img={order.image}
						content={order.content}
						mode={mode}
						isSample={isSample}
					/>
				</div>
				<div className='f-order-state'>
					{mode === 'send' ? (
						<>
							<h1 className='f-order-state-title'>Estado del envío</h1>
							{order.state === 'DEVOLUCION' || order.state === 'CANCELADO' ? (
								<StateError
									state={
										order.state === 'DEVOLUCION' ? 'EN DEVOLUCIÓN' : 'CANCELADO'
									}
									description={order.description}
								/>
							) : (
								<div className='f-order-state-container'>
									<StateSend
										states={[
											{
												name: 'Ciudad de origen',
												color: '#62c224',
												tag: 'first',
												active: STATUS_ORDERS.ORIGEN === order.state,
												icon: 'icon-map-marker-alt f-icon--center white',
											},
											{
												name: 'En reparto',
												color: '#62c224',
												active: STATUS_ORDERS.REPARTO === order.state,
												icon: 'icon-truck f-icon--center white',
											},
											{
												name: 'Entregado',
												color: '#62c224',
												tag: 'last',
												active: STATUS_ORDERS.ENTREGADO === order.state,
												icon: 'icon-home f-icon--center white',
											},
										]}
									/>

									<div className='f-button-container'>
										<button
											className={`f-faqcard-button ${
												isActiveDropDownCard ? 'f-faqcard-button--active' : ''
											}`}
											onClick={() => {
												getInfoOrder(order.id)
												setIsActiveDropDownCard(true)
											}}
										>
											Ver detalles{' '}
										</button>
									</div>
								</div>
							)}
						</>
					) : (
						<div className='f-button-container'>
							<button
								className={`f-faqcard-button ${
									isActiveDropDownCard ? 'f-faqcard-button--active' : ''
								}`}
								onClick={() => {
									getInfoOrder(order.id)
									setIsActiveDropDownCard(true)
								}}
							>
								Ver detalles{' '}
							</button>
						</div>
					)}
				</div>
			</div>
			{isActiveDropDownCard && infoOrder?.products && (
				<DropDownCard
					items={infoOrder}
					isActiveDropDownCard={isActiveDropDownCard}
					setIsActiveDropDownCard={() => setIsActiveDropDownCard(false)}
					isShowingLoader={isShowingLoader}
				/>
			)}
		</div>
	)
}

Order.propTypes = {
	mode: PropTypes.oneOf(['send', 'detail']).isRequired,
}

export default Order
